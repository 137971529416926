import React, { useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Form } from 'react-final-form';

import CrossCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircleOutline.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { required } from '@travauxlib/shared/src/utils/form';

import { useAuth } from 'api/useAuth';
import { useCloseChantier } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useCloseChantier';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { FicheChantiersTracking } from 'utils/tracking/FicheChantiersTracking';

type Props = {
  chantier: Chantier;
};

type ChantierCloseFormData = {
  dateFinReelle: Dayjs;
};

export const CloseChantier: React.FC<Props> = ({ chantier }) => {
  const { closeChantier } = useCloseChantier();
  const { adminUserProfile, isLoading } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const isAllowedToCloseChantier = useIsAllowed('Chantier', 'close');
  const onCloseModal = (): void => setModalVisible(false);
  const onSubmit = async (values: ChantierCloseFormData): Promise<void> => {
    FicheChantiersTracking.onChantierClosed({
      'User Role': adminUserProfile.role,
      'Chantier UUID': chantier.uuid,
    });
    await closeChantier({
      ...chantier,
      dateFinReelle: values.dateFinReelle,
    });
    onCloseModal();
  };

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <>
      <Button
        leftIcon={<CrossCircleOutline />}
        type="button"
        variant="secondary"
        disabled={!isAllowedToCloseChantier}
        onClick={() => setModalVisible(true)}
        size="sm"
      >
        Clore le chantier
      </Button>
      <Modal
        isOpen={modalVisible}
        handleClose={onCloseModal}
        title="Fin du chantier"
        isScrollable={false}
      >
        <Form<ChantierCloseFormData>
          onSubmit={async values => {
            await onSubmit(values);
          }}
        >
          {({ handleSubmit, invalid, submitting }) => (
            <ModalContent
              handleSubmit={handleSubmit}
              validateAction={{
                label: 'Valider',
                type: 'submit',
                disabled: invalid || submitting,
                loading: submitting,
              }}
              cancelAction={{ label: 'Annuler', onClick: onCloseModal }}
            >
              <div className="mb-md">
                <DatePickerField
                  name="dateFinReelle"
                  id="dateFinReelle"
                  label="Date de fin réelle"
                  validate={required}
                  shouldBeDisabled={(date: Date) =>
                    dayjs(date).isAfter(dayjs(), 'day') ||
                    (!!chantier.dateDebutReelle && dayjs(date).isBefore(chantier.dateDebutReelle))
                  }
                  readOnly
                />
              </div>
            </ModalContent>
          )}
        </Form>
      </Modal>
    </>
  );
};
