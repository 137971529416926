import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import Cross from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import SearchEmptyState from '@travauxlib/shared/src/components/DesignSystem/assets/SearchEmptyState.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { DevisComparatorGrid } from './DevisComparatorGrid';
import { DevisComparatorInput } from './DevisComparatorInput';

import { useDevisForComparator } from '../api/useDevisForComparator';

export const DevisComparatorLayout: React.FC = () => {
  const { token1, token2 } = useParams();

  const [showStats, setShowStats] = useState<boolean>(false);

  const {
    devis: firstDevis,
    isLoading: firstDevisLoading,
    error: firstDevisError,
  } = useDevisForComparator(token1);

  const {
    devis: secondDevis,
    isLoading: secondDevisLoading,
    error: secondDevisError,
  } = useDevisForComparator(token2);

  const error = firstDevisError || secondDevisError;
  const isLoading = firstDevisLoading || secondDevisLoading;

  return (
    <>
      <div className="bg-neutral-100 py-xl min-h-[100vh]">
        <div className="sm-desktop:px-xl">
          <>
            <DevisComparatorInput
              key={`${token1}-${token2}`}
              isLoading={isLoading}
              toggleShowStats={() => setShowStats(showStats => !showStats)}
              showStats={showStats}
            />

            {error && (
              <EmptyState
                title="Erreur lors de la récupération de la configuration ou des devis"
                description="Un des tokens de ne doit pas être valide."
                icon={<Cross />}
              />
            )}
            {!isLoading && (!token1 || !token2) && (
              <EmptyState
                title="Vous n’avez pas encore renseigné de devis ou de configuration"
                description="Pour lancer la comparaison, les deux token doivent être renseignés dans leur champ respectif
          puis vous devez appuyer sur le bouton “Comparer”."
                illustration={<SearchEmptyState />}
              />
            )}
            {isLoading && <Loader />}
          </>
        </div>

        {firstDevis && secondDevis && (
          <DevisComparatorGrid
            showStats={showStats}
            firstDevis={firstDevis}
            secondDevis={secondDevis}
          />
        )}
      </div>
      {firstDevis && secondDevis && (
        <div className="sm-desktop:hidden bg-neutral-0 w-full text-b1 shadow-xl p-md pt-sm gap-xxs flex flex-col bottom-0 sticky">
          <div className="flex">
            Différence
            <span className="ml-auto font-bold">
              {secondDevis.prixTotalHT - firstDevis.prixTotalHT > 0 && '+'}
              <EURCurrency amount={secondDevis.prixTotalHT - firstDevis.prixTotalHT} />
              &nbsp;HT
            </span>
          </div>
        </div>
      )}
    </>
  );
};
