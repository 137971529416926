import React from 'react';

import { Link } from 'react-router-dom';

import BurgerMenu from '@travauxlib/shared/src/components/DesignSystem/assets/BurgerMenu.svg?react';
import logo from '@travauxlib/shared/src/components/DesignSystem/assets/Logo.svg';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

import { useAuth } from 'api/useAuth';

type Props = {
  openSidebar: () => void;
};

export const Header: React.FC<Props> = ({ openSidebar }) => {
  const { adminUserProfile } = useAuth();

  return (
    <header className="bg-white px-xxs py-xs fixed top-0 left-0 w-full h-3xl z-30 shadow-sm sm-desktop:hidden flex justify-between items-center">
      <IconButton size="lg" onClick={openSidebar}>
        <BurgerMenu />
      </IconButton>
      <Link to="/">
        <img src={logo} className="h-xl w-[116px] shrink-0 bg-contain" />
      </Link>
      <Link className="m-xs" to={`/trombinoscope/${adminUserProfile.uuid}`}>
        <Avatar firstName={adminUserProfile.firstName} lastName={adminUserProfile.lastName} />
      </Link>
    </header>
  );
};
