import React from 'react';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { Tab } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs/types';

import { useIsAllowed } from 'hooks/useIsAllowed';

import { useTeams } from '../api/useTeams';

export const Header: React.FC = () => {
  const { teams } = useTeams();
  const isAllowedToSeeAdminTeam = useIsAllowed('Person', 'manage');
  const tabItems: Tab[] = teams
    .filter(t => t.label !== 'Admin' || isAllowedToSeeAdminTeam)
    .map(({ uuid, label }) => ({
      label,
      to: `/trombinoscope/teams/${uuid}`,
      isActive: location => location.pathname.includes(`/trombinoscope/teams/${uuid}`),
    }));

  return (
    <div className="bg-neutral-0 pt-sm shadow-sm bg-neutral-0 sm-desktop:-mx-xl -mx-md -mt-md px-xl">
      <div className="container w-full sm-desktop:w-full">
        <h1 className="mb-md font-bold">Trombinoscope</h1>
        <div className="flex justify-around flex-wrap">
          <Tabs
            items={[
              {
                label: 'Tous',
                to: '/trombinoscope',
                isActive: location => location.pathname === '/trombinoscope',
              },
              ...tabItems,
            ]}
            className="flex-1"
            hideContainerBottomBorder
          />
        </div>
      </div>
    </div>
  );
};
