import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { PersonRole } from '@travauxlib/shared/src/types';

import { usePersons } from 'features/Trombinoscope/api/usePersons';
import { useIsAllowed } from 'hooks/useIsAllowed';

import { FacturationCDPL } from './FacturationCDPL';
import { LegalInfosCDPL } from './LegalInfosCDPL';

export type Props = {
  siret?: string;
  personUuid: string;
};

export const EditCDPL: React.FC<Props> = ({ siret, personUuid }) => {
  const { persons: cdpl, isLoading } = usePersons([PersonRole.CDPL]);
  const isAllowedToEditProfile = useIsAllowed('Person', 'manage');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="mt-md">CDPL</h2>
      <div className="flex flex-wrap -mx-md !relative">
        <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
          <DropdownField
            label="Recruteur"
            id="recruiter-input"
            name="recruiterUuid"
            disabled={!isAllowedToEditProfile}
            options={cdpl.map(p => ({
              label: `${p.firstName} ${p.lastName}`,
              value: p.uuid,
            }))}
          />
        </div>
      </div>
      <h2 className="my-md">Entreprise</h2>
      <Card className="mb-md overflow-visible">
        <LegalInfosCDPL siret={siret} />
      </Card>
      <h2 className="my-md">Facturation</h2>
      <FacturationCDPL personUuid={personUuid} />
    </>
  );
};
