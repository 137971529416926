import React from 'react';

import { Navigate, Outlet, useParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useConfiguration } from './api/useConfiguration';
import { ConfigurateurNotes } from './components/Notes';

export const Configurateur: React.FC = () => {
  const projectUuid = useParams().uuid!;
  const { configuration, isLoading, isFetching } = useConfiguration(projectUuid);

  // there's a refetch happening after the creation of the configuration
  // without this condition, it will redirect erroneously
  if (isLoading || (!configuration && isFetching)) {
    return <Loader />;
  }

  if (!configuration) {
    return <Navigate to={`/projects/${projectUuid}`} />;
  }

  return (
    <>
      <Outlet />
      <ConfigurateurNotes />
    </>
  );
};
