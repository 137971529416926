import React from 'react';

import { Form } from 'react-final-form';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { composeValidators, required } from '@travauxlib/shared/src/utils/form';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import {
  TypePrestationEncaissement,
  useEncaisserPrestationInterne,
} from '../../api/useEncaisserPrestationInterne';

type EncaisserPrestationFormData = {
  montantAEncaisser: number;
};

type Props = {
  chantier: Chantier;
  typePrestation: TypePrestationEncaissement;
  labelPrestation: string;
  montantTTCPrestation: number;
  montantTTCEncaissePrestation: number;
  handleClose: () => void;
};

export const EncaisserPrestationInterneModal: React.FC<Props> = ({
  chantier,
  typePrestation,
  labelPrestation,
  montantTTCPrestation,
  montantTTCEncaissePrestation,
  handleClose,
}) => {
  const { encaisserPrestationInterne } = useEncaisserPrestationInterne();

  const montantRestantAEncaisserTTC = Math.max(
    0,
    roundToTwoDecimals(montantTTCPrestation - montantTTCEncaissePrestation),
  );

  const montantRestantEncaissable = roundToTwoDecimals(
    Math.min(chantier.soldeCompteSequestre, montantRestantAEncaisserTTC),
  );

  const validateMontantAEncaisser = (montantAEncaisser: number): undefined | string =>
    montantAEncaisser === 0 || montantAEncaisser > montantRestantEncaissable
      ? 'Montant invalide'
      : undefined;

  const onSubmit = async (values: EncaisserPrestationFormData): Promise<void> => {
    await encaisserPrestationInterne({
      slug: chantier.slug,
      typePrestation: typePrestation,
      montantAEncaisser: values.montantAEncaisser,
    });
    handleClose();
  };

  return (
    <Modal isOpen={true} handleClose={handleClose} title={`Encaissement ${labelPrestation}`}>
      <Form<EncaisserPrestationFormData>
        onSubmit={onSubmit}
        initialValues={{
          montantAEncaisser: Math.min(montantRestantEncaissable, montantRestantAEncaisserTTC),
        }}
      >
        {({ handleSubmit, invalid, submitting, values }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            validateAction={{
              label: 'Valider',
              type: 'submit',
              disabled: invalid || submitting,
              loading: submitting,
            }}
            cancelAction={{ label: 'Annuler', onClick: handleClose }}
          >
            <>
              <strong>Avant</strong>
              <div className="!mb-lg">
                Restant à encaisser :{' '}
                <EURCurrency amount={montantRestantAEncaisserTTC} forceFullPattern />
                <br />
                Restant encaissable :{' '}
                <EURCurrency amount={montantRestantEncaissable} forceFullPattern />
              </div>
              <NumberInputField
                className="mb-md"
                name="montantAEncaisser"
                id="montantAEncaisser"
                label="Montant à encaisser"
                min={0}
                fractionDigits={2}
                suffix="€"
                validate={composeValidators(required, validateMontantAEncaisser)}
              />
              <strong>Après</strong>
              <div className="!mb-lg">
                Restant à encaisser :{' '}
                <EURCurrency
                  amount={roundToTwoDecimals(
                    montantRestantAEncaisserTTC - values.montantAEncaisser,
                  )}
                  forceFullPattern
                />
                <br />
                Restant encaissable :{' '}
                <EURCurrency
                  amount={roundToTwoDecimals(montantRestantEncaissable - values.montantAEncaisser)}
                  forceFullPattern
                />
              </div>
            </>
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
