import React, { useEffect } from 'react';

import { Outlet, Navigate, useParams, useLocation } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { useChantier } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantier';
import { SuiviChantier } from 'features/SuiviChantierV2/Loadable';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { ChantierListing } from './components/ChantierListing';
import { Debug } from './components/Debug';

const Index: React.FC = () => {
  useEffect(() => {
    NavigationTracking.onChantiersViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.chantiers} />
      <Outlet />
    </>
  );
};

const ChantierDetailsWithSlug: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { hash } = useLocation();

  const { chantier } = useChantier(slug!);
  if (!chantier) {
    return null;
  }
  return (
    <Navigate to={`/projects/${chantier.project?.uuid}/chantier/${chantier.slug}${hash}`} replace />
  );
};

const ChantierDebugWithSlug: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { chantier } = useChantier(slug!);
  if (!chantier) {
    return <Loader />;
  }
  return <Debug chantier={chantier} />;
};

export const Chantiers = {
  Index,
  ChantierDetails: ChantierDetailsWithSlug,
  ChantierDebug: ChantierDebugWithSlug,
  ChantierListing,
  SuiviChantier,
};
