import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';

type Props = {
  label: string;
  hasRight: boolean;
};

export const PermissionItem: React.FC<Props> = ({ label, hasRight }) => (
  <div className="text-b2 my-xs flex items-center col-span-4">
    <CheckCircle
      className={classNames('w-lg mr-sm', hasRight ? 'text-success' : 'text-neutral-300')}
    />
    <span className={!hasRight ? 'text-neutral-300' : ''}>{label}</span>
  </div>
);
