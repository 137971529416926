import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = {
  chantier: ChantierAdminListItemView;
  proCompany?: ProCompanyAdminView;
};

const fields = [
  {
    name: 'montantProvisionInitialEuros',
    label: 'Montant de provision initial',
    fieldType: 'numberInput',
    initialValue: 500000,
    required: true,
  },
] as const;

export class ChantierMockLemonway extends BaseStep<Input, void, typeof fields> {
  public name = 'Mock Lemonway on Chantier';

  public fields = fields;

  async execute({
    chantier,
    proCompany,
    apiURL,
    request,
    montantProvisionInitialEuros = 500000,
  }: ExecuteParams<Input, typeof fields>): Promise<void> {
    if (!proCompany) {
      super.error(`No pro company passed for lemonway mocking of chantier ${chantier.slug}`);
      return;
    }

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/debug/toggle-lemonway-mock`, {
      method: 'POST',
    });

    super.log('Mocked lemonway account');

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/fix-lemonway-account`, {
      method: 'POST',
    });

    super.log('Fixed lemonway client account');

    await request(
      `${apiURL}/admin/pro-companies/${proCompany.slug}/createLemonwayWallet?withMockedLemonway=true`,
      { method: 'PUT' },
    );

    super.log('Created pro LW wallet');

    await request(`${apiURL}/admin/chantiers/${chantier.slug}/debug/add-provision`, {
      method: 'POST',
      body: {
        montantProvision: montantProvisionInitialEuros * 100,
      },
    });

    super.log('Giving insane amount of money');
  }
}
