import { Person, Team, TeamsLabel } from '@travauxlib/shared/src/types';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'teamToAssign',
    initialValue: 'Ops',
    options: Object.values(TeamsLabel).map(value => ({ value, label: value })),
    fieldType: 'dropdown',
  },
] as const;

export class CreateEmployeeStep extends BaseStep<
  {},
  { employee: Person },
  typeof fields,
  { employee: Person }
> {
  public name: string = 'Create employee';

  public fields = fields;

  async execute({
    seed,
    teamToAssign,
    apiURL,
    adminURL,
    request,
  }: ExecuteParams<{}, typeof fields>): Promise<{ employee: Person }> {
    super.log('Creating employee...');

    const person = {
      firstName: 'John',
      lastName: `CDP_${seed}`,
      email: `john.CDP_${seed}@example.com`,
      jobTitle: 'osef',
      arrivalDate: '2020-10-01',
      role: 'team_lead_partenariat',
    };
    const employee = await request<Person>(`${apiURL}/admin/employees/persons`, {
      method: 'POST',
      body: person,
    });

    super.log(`Employee created : ${adminURL}/trombinoscope/${employee.uuid}`);
    super.log(`Fetching all teams to assign employee to ${teamToAssign} team`);

    const allTeams = await request<{ data: Team[] }>(`${apiURL}/admin/employees/teams`);
    const team = allTeams.data.find(team => team.label === teamToAssign);

    await request(`${apiURL}/admin/employees/teams/${team?.uuid}/employees/${employee.uuid}`, {
      method: 'POST',
    });

    const employeeWithLeadAssignment = {
      ...employee,
      config: {
        leadAssignment: {
          budgets: [
            '10000-30000',
            '30000-50000',
            '50000-100000',
            '100000-250000',
            '+250000',
            '0-10000',
          ],
          acceptPaid: true,
          acceptHemea: true,
          acceptPartner: true,
          availability: true,
          departments: [
            '01',
            '31',
            '33',
            '38',
            '42',
            '64',
            '69',
            '73',
            '74',
            '75',
            '77',
            '78',
            '81',
            '82',
            '91',
            '92',
            '93',
            '94',
            '95',
          ],
        },
      },
    };

    await request(`${apiURL}/admin/employees/persons/${employee.uuid}`, {
      method: 'PUT',
      body: employeeWithLeadAssignment,
    });

    super.log('Employee updated with success');

    super.setCreatedEntities({ employee });

    return { employee: employeeWithLeadAssignment };
  }
}
