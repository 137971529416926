import React, { useState } from 'react';

import dayjs from 'dayjs';
import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { TypeSuivi } from '@travauxlib/shared/src/types';
import { ChantierStatus } from '@travauxlib/shared/src/types/api/admin/ChantierStatus';
import { typeSuiviWordings } from '@travauxlib/shared/src/utils/constants';
import { required } from '@travauxlib/shared/src/utils/form';
import { formatLocalDate } from '@travauxlib/shared/src/utils/time';

import { useAuth } from 'api/useAuth';
import { useSuiviByOptions } from 'features/Chantiers/api/useSuiviByOptions';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { FicheChantiersTracking } from 'utils/tracking/FicheChantiersTracking';

import { useUpdateChantier } from '../../api/useUpdateChantier';

type Props = {
  chantier: Chantier;
};

export const ChantierEdit: React.FC<Props> = ({ chantier }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { updateChantier } = useUpdateChantier();
  const { adminUserProfile } = useAuth();
  const [selectedTypeSuivi, setSelectedTypeSuivi] = useState<TypeSuivi>(chantier.typeSuivi);

  const onCloseModal = (): void => setModalVisible(false);
  const { allMembers, ccMembers, archiInterneMembers, TPMembers } = useSuiviByOptions();
  const optionsBySuivi = {
    [TypeSuivi.Architecte]: archiInterneMembers,
    [TypeSuivi.CustomerCare]: allMembers,
    [TypeSuivi.ServiceHemea]: allMembers,
    [TypeSuivi.TravauxPlanner]: TPMembers,
    [TypeSuivi.TravauxPlannerWithService]: TPMembers,
  };
  const isAllowedToUpdateAdvancedChantierSettings = useIsAllowed('Chantier', 'useAdvancedActions');

  return (
    <>
      <Button
        disabled={!isAllowedToUpdateAdvancedChantierSettings}
        onClick={() => setModalVisible(true)}
        size="sm"
        variant="secondary"
      >
        Modifier
      </Button>
      <Modal
        isOpen={modalVisible}
        handleClose={onCloseModal}
        title="Modifier les détails du projet"
      >
        <Form
          onSubmit={async values => {
            const {
              dateDebutReelle,
              dateFinReelle,
              dateAnnulation,
              typeSuivi,
              responsableSuiviUuid,
              coachCareUuid,
              dateDebutEstimeeInterne,
              dateFinEstimee,
            } = values;

            FicheChantiersTracking.onInfoChantierEdited({
              'User Role': adminUserProfile.role,
              'Chantier UUID': chantier.uuid,
            });
            const dureeEstimeeDaysInterne =
              dayjs(dateFinEstimee).diff(
                dayjs(dateDebutReelle ? dateDebutReelle : dateDebutEstimeeInterne),
                'days',
              ) + 1;

            updateChantier({
              slug: chantier.slug,
              dateDebutReelle: formatLocalDate(dateDebutReelle),
              dateFinReelle: formatLocalDate(dateFinReelle),
              dateAnnulation: formatLocalDate(dateAnnulation),
              responsableSuiviUuid,
              coachCareUuid,
              typeSuivi,
              dateDebutEstimeeInterne: formatLocalDate(dateDebutEstimeeInterne),
              dureeEstimeeDaysInterne,
            });

            onCloseModal();
          }}
          initialValues={chantier}
        >
          {({ handleSubmit, invalid, submitting, values, form }) => (
            <ModalContent
              handleSubmit={handleSubmit}
              validateAction={{
                label: 'Valider',
                type: 'submit',
                disabled: invalid || submitting || !isAllowedToUpdateAdvancedChantierSettings,
                loading: submitting,
              }}
              cancelAction={{ label: 'Annuler', onClick: onCloseModal }}
            >
              <DropdownField
                className="mb-md"
                name="typeSuivi"
                id="typeSuivi"
                label="Suivi"
                validate={required}
                onChange={value => {
                  form.change('typeSuivi', value);
                  setSelectedTypeSuivi(value);
                }}
                options={[
                  typeSuiviWordings.service_hemea,
                  typeSuiviWordings.travaux_planner_with_service,
                  typeSuiviWordings.customer_care,
                  typeSuiviWordings.travaux_planner,
                  typeSuiviWordings.architecte,
                ]}
              />
              <DropdownField
                className="mb-md"
                name="responsableSuiviUuid"
                id="responsableSuiviUuid"
                label="Responsable Suivi"
                options={optionsBySuivi[selectedTypeSuivi]}
              />
              <InlineAlert level="info">
                Le Coach Care n'est à renseigner que lorsqu'un TP, un CDPL ou un architecte effectue
                le suivi du chantier
              </InlineAlert>
              <DropdownField
                name="coachCareUuid"
                data-testid="coachCareUuid"
                id="coachCareUuid"
                label="Coach Care"
                defaultValue={chantier.coachCareUuid}
                options={ccMembers}
              />
              <hr />
              {chantier.status === ChantierStatus.NonDemarre ? (
                <DatePickerField
                  className="mb-md"
                  name="dateDebutEstimeeInterne"
                  id="dateDebutEstimeeInterne"
                  label="Date de début estimée"
                  initialValue={chantier.dateDebutEstimee}
                />
              ) : (
                <DatePickerField
                  className="mb-md"
                  name="dateDebutReelle"
                  id="dateDebutReelle"
                  label="Date de début réelle"
                />
              )}
              {chantier.dateFinReelle ? (
                <DatePickerField
                  className="mb-md"
                  name="dateFinReelle"
                  data-testid="dateFinReelle"
                  id="dateFinReelle"
                  label="Date de fin réelle"
                />
              ) : (
                <DatePickerField
                  className="mb-md"
                  name="dateFinEstimee"
                  id="dateFinEstimee"
                  data-testid="dateFinEstimee"
                  label="Date de fin estimée"
                  value={dayjs(values.dateDebutEstimeeInterne).add(
                    chantier.dureeEstimeeDaysInterne ?? 0,
                    'days',
                  )}
                />
              )}
              <DatePickerField
                name="dateAnnulation"
                id="dateAnnulation"
                label="Date d'annulation"
              />
            </ModalContent>
          )}
        </Form>
      </Modal>
    </>
  );
};
