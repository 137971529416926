import React from 'react';

import { Outlet, useParams } from 'react-router-dom';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { Toggle } from '@travauxlib/shared/src/components/DesignSystem/components/Toggle';

import { useIsAllowed } from 'hooks/useIsAllowed';

import { useDevisModalites } from '../api/useDevisModalites';
import { useDevisModalitesPartialUpdate } from '../api/useDevisModalitesUpdate';
import { useToggleServiceHemea } from '../api/useToggleServiceHemea';

type Params = {
  devisToken: string;
};

type Props = {
  label: string;
};
export const SeparationTag: React.FC<Props> = ({ label }) => (
  <Tag label={label} variant="light" size="md" />
);

export const ModalitesDevis: React.FC = () => {
  const { devisToken } = useParams<Params>();
  const { isLoading, data: modalitesDevis } = useDevisModalites(devisToken!);
  const updateModalite = useDevisModalitesPartialUpdate();
  const toggleServiceHemea = useToggleServiceHemea(devisToken!);
  const isAllowedToActivateSuiviRenforce = useIsAllowed('Chantier', 'activateSuiviRenforce');

  if (isLoading || !modalitesDevis) {
    return <Loader />;
  }

  if (modalitesDevis && !modalitesDevis.isHemea) {
    return (
      <AlertMessage level="error" title="Impossible de modifier les modalités du devis">
        Le devis sélectionné n'est pas lié a un deal hemea
      </AlertMessage>
    );
  }

  if (modalitesDevis && modalitesDevis.archiType === 'interne') {
    return (
      <AlertMessage level="error" title="Impossible de modifier les modalités du devis">
        Le devis sélectionné est lié a un deal d'archi interne
      </AlertMessage>
    );
  }

  return (
    <>
      <Card>
        <h2>{modalitesDevis.title}</h2>
        <h5>{modalitesDevis.address}</h5>
        <Toggle
          label="Service hemea"
          checked={modalitesDevis.hasServiceHemeaIncluded}
          onChange={() => toggleServiceHemea()}
          disabled={modalitesDevis.devisSigned}
        />{' '}
        <br />
        <Toggle
          label="Suivi financier renforcé"
          checked={modalitesDevis.modalites?.enableSuiviRenforce}
          onChange={() =>
            updateModalite(
              {
                devisToken: devisToken!,
                targetKey: 'enableSuiviRenforce',
                body: {
                  newEnabled: !(modalitesDevis.modalites?.enableSuiviRenforce ?? false),
                },
              },
              {},
            )
          }
          disabled={modalitesDevis.devisSigned || !isAllowedToActivateSuiviRenforce}
        />
      </Card>
      <AlertMessage className="mt-md" level="info" title="Modalités par defaut">
        Taux commission hemea par défaut: {modalitesDevis.defaultModalites.tauxCommissionHemea}%
        <br />
        Taux Travaux Planner par défaut: {modalitesDevis.defaultModalites.tauxTravauxPlanner}%
        <br />
        {modalitesDevis.hasServiceHemeaIncluded &&
          `Service hemea par défaut: ${modalitesDevis?.defaultModalites.tauxServiceHemea}%`}
      </AlertMessage>
      <AlertMessage className="my-md" level="warning" title="Attention">
        Vous ne pouvez pas sauvegarder ni réinitialiser les taux appliqués si vous n'avez pas les
        droits nécessaires
        <br />
        Les modalités peuvent être modifiées uniquement avant la signature du devis
        <br />
        La modification affectera <b>uniquement</b> ce devis. Les autres devis du deal seront
        inchangés
        <br />
        La TVA appliquée pour la commission est de 20%, elle n'a aucun rapport avec la TVA du devis
      </AlertMessage>
      <Tabs
        items={[
          { label: 'Commission', to: 'commission' },
          { label: 'Prestations hemea', to: 'hemea' },
          { label: 'Modalités paiement', to: 'modalites-paiment' },
        ]}
      />
      <div className="py-md">
        <Outlet />
      </div>
    </>
  );
};
