import React, { useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Form } from 'react-final-form';

import HandyMan from '@travauxlib/shared/src/components/DesignSystem/assets/HandyMan.svg?react';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { required } from '@travauxlib/shared/src/utils/form';

import { useAuth } from 'api/useAuth';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { FicheChantiersTracking } from 'utils/tracking/FicheChantiersTracking';

import { eventCardClassName } from './constants';

import { useLaunchChantier } from '../../api/useLaunchChantier';

type Props = {
  chantier: Chantier;
};

type ChantierLaunchFormData = {
  dateDebutReelle: Dayjs;
};

export const DemarrerChantier: React.FC<Props> = ({ chantier }) => {
  const { launchChantier } = useLaunchChantier();
  const { adminUserProfile } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const onCloseModal = (): void => setModalVisible(false);
  const onSubmit = async (values: ChantierLaunchFormData): Promise<void> => {
    FicheChantiersTracking.onChantierOpened({
      'User Role': adminUserProfile.role,
      'Chantier UUID': chantier.uuid,
    });

    await launchChantier({
      ...chantier,
      dateDebutReelle: values.dateDebutReelle,
    });
    onCloseModal();
  };

  const dateDebutEstimee = dayjs(chantier.dateDebutEstimee).startOf('day');
  const isAllowedToStartChantier = useIsAllowed('Chantier', 'open');

  return (
    <>
      {!chantier.dateDebutReelle ? (
        <EventCard
          className={eventCardClassName}
          icon={<HandyMan />}
          title="Démarrer le chantier"
          variant="beige"
          action={{
            label: 'Démarrer le chantier',
            onClick: () => setModalVisible(true),
            disabled: !isAllowedToStartChantier,
            hideIcon: true,
          }}
        >
          <></>
        </EventCard>
      ) : (
        <></>
      )}
      <Modal
        isOpen={modalVisible}
        handleClose={onCloseModal}
        title="Démarrage du chantier"
        isScrollable={false}
      >
        <Form<ChantierLaunchFormData> onSubmit={onSubmit}>
          {({ handleSubmit, invalid, submitting, values }) => {
            const dateDebutReelle = dayjs(values.dateDebutReelle).startOf('day');
            const diffReelleEstimee = dateDebutReelle.diff(dateDebutEstimee, 'day');

            return (
              <ModalContent
                handleSubmit={handleSubmit}
                validateAction={{
                  label: 'Valider',
                  type: 'submit',
                  disabled: invalid || submitting || !isAllowedToStartChantier,
                  loading: submitting,
                }}
                cancelAction={{ label: 'Annuler', onClick: onCloseModal }}
              >
                <>
                  <DatePickerField
                    className="mb-md"
                    name="dateDebutReelle"
                    id="dateDebutReelle"
                    label="Date de démarrage réel"
                    validate={required}
                    shouldBeDisabled={(date: Date) => dayjs(date).isAfter(dayjs(), 'day')}
                  />
                  {values.dateDebutReelle && (
                    <div className="relative px-lg py-sm rounded bg-info-50 items-center mb-xl">
                      🕗 Le chantier a donc démarré&nbsp;
                      {diffReelleEstimee < 0 && (
                        <>
                          <strong>
                            {-diffReelleEstimee} jour
                            {diffReelleEstimee < -1 ? 's' : ''} plus tôt
                          </strong>{' '}
                          que prévu
                        </>
                      )}
                      {diffReelleEstimee === 0 && <strong>à la date prévue</strong>}
                      {diffReelleEstimee > 0 && (
                        <>
                          <strong>
                            {diffReelleEstimee} jour
                            {diffReelleEstimee > 1 ? 's' : ''} plus tard
                          </strong>{' '}
                          que prévu
                        </>
                      )}{' '}
                      dans le devis.
                    </div>
                  )}
                </>
              </ModalContent>
            );
          }}
        </Form>
      </Modal>
    </>
  );
};
