import React from 'react';

import ManageAccounts from '@travauxlib/shared/src/components/DesignSystem/assets/ManageAccounts.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { Person } from '@travauxlib/shared/src/types';

import { personRoleToLabel } from 'utils/constants';

type Props = {
  person: Person;
  to: string;
};

export const PersonCard: React.FC<Props> = ({ person, to }) => {
  const { firstName, lastName, jobTitle, role } = person;

  const officialPicture = person.officialPicture || 'https://picsum.photos/224/224';

  const bonusPicture = person.bonusPicture || officialPicture;
  return (
    <Card data-testid="person-card" to={to} state="elevated" className="w-[13rem] h-[17.5rem]">
      <div className="bg-transparent p-0 [perspective]-[1000px] [&:hover>*]:[transform:rotateY(180deg)]">
        <div className="relative w-[11rem] h-[11rem] duration-[800ms] [transform-style:preserve-3d]">
          <div className="absolute [backface-visibility:hidden]">
            <ImgLazy
              alt="main"
              url={officialPicture}
              width={176}
              height={176}
              imgixParams={{
                fit: 'crop',
                crop: 'top',
              }}
            />
          </div>
          <div className="absolute [backface-visibility:hidden] [transform:rotateY(180deg)]">
            <ImgLazy
              alt="main"
              url={bonusPicture}
              width={224}
              height={224}
              imgixParams={{
                fit: 'crop',
                crop: 'top',
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-left mt-md">
        <div className="text-b2 font-bold truncate">
          {firstName} {lastName}
        </div>
        <div className="text-sm truncate text-neutral-600 mb-xxs">{jobTitle}</div>
        <Tag
          icon={<ManageAccounts />}
          size="sm"
          variant="light"
          label={role ? personRoleToLabel[role] : 'Pas de rôle'}
        />
      </div>
    </Card>
  );
};
