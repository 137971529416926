import Bank from '@travauxlib/shared/src/components/DesignSystem/assets/Bank.svg?react';
import { Chantier, ChantierTransaction } from '@travauxlib/shared/src/features/Chantiers/types';

import { createActionCreators, createBaseHistoryItem } from './baseHistoryItem';

import { FluxFinancierHistoriqueFilterType, HistoryItem } from '../types';

export const provisionToHistoryItem =
  (
    handleOpenModalIgnoreTransaction: (
      transaction: ChantierTransaction,
      chantier: Chantier,
    ) => void,
    handleOpenModalChangeTransactionVisibility: (
      transaction: ChantierTransaction,
      chantier: Chantier,
    ) => void,
    chantier: Chantier,
    isAllowedToManageTransactions: boolean,
  ) =>
  (transaction: ChantierTransaction): HistoryItem =>
    createBaseHistoryItem(
      transaction,
      'Provision du compte',
      FluxFinancierHistoriqueFilterType.Provision,
      Bank,
      'info',
      createActionCreators(
        handleOpenModalIgnoreTransaction,
        handleOpenModalChangeTransactionVisibility,
        chantier,
        isAllowedToManageTransactions,
      ),
    );
