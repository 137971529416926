import React from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useGetProjectOfDevis } from '../api/useGetProjectOfDevis';

export const RedirectToProjectOfDevis: React.FC = () => {
  const { devisToken } = useParams<{ devisToken: string }>();
  const { isLoading, data, isError } = useGetProjectOfDevis(devisToken);

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !data?.projectUuid) {
    return <Navigate to="/projects" />;
  }

  if (data.projectUuid) {
    return <Navigate to={`/projects/${data.projectUuid}/devis`} />;
  }

  return <></>;
};
