import { PersonRole } from '@travauxlib/shared/src/types';

import { Ability } from './AbilityBuilder';
import { admin } from './entities/Admin';
import { architecteInterne } from './entities/architecteInterne';
import { cdp } from './entities/cdp';
import { cdpl } from './entities/cdpl';
import { compta } from './entities/compta';
import { customerCare } from './entities/customerCare';
import { customerSuccessManager } from './entities/customerSuccessManager';
import { noRole } from './entities/NoRole';
import { recruteur } from './entities/recruteur';
import { responsableAcquisition } from './entities/responsableAcquisition';
import { responsablePartenariat } from './entities/responsablePartenariat';
import { rh } from './entities/rh';
import { teamLeadCDP } from './entities/TeamLeadCDP';
import { teamLeadMarketing } from './entities/teamLeadMarketing';
import { teamLeadPartenariat } from './entities/teamLeadPartenariat';
import { teamLeadQualite } from './entities/TeamLeadQualite';
import { tpExterne } from './entities/TPExterne';
import { tpInterne } from './entities/TPInterne';
import { Action, Subject, Flag } from './entities/types';

export const abilityMap: { [K in PersonRole]: Ability<Action, Subject, Flag> } = {
  admin,
  team_lead_partenariat: teamLeadPartenariat,
  responsable_partenariat: responsablePartenariat,
  team_lead_qualite: teamLeadQualite,
  customer_success_manager: customerSuccessManager,
  customer_care: customerCare,
  travaux_planner_interne: tpInterne,
  travaux_planner_externe: tpExterne,
  team_lead_cdp: teamLeadCDP,
  cdp,
  cdpl,
  compta,
  rh,
  recruteur,
  team_lead_marketing: teamLeadMarketing,
  responsable_acquisition: responsableAcquisition,
  no_role: noRole,
  architecte_interne: architecteInterne,
};
