import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const teamLeadQualiteBuilder = new AbilityBuilder<Action, Subject, Flag>();

teamLeadQualiteBuilder.can('toggleRelance', 'Chantier');
teamLeadQualiteBuilder.can('open', 'Chantier');
teamLeadQualiteBuilder.can('close', 'Chantier');
teamLeadQualiteBuilder.can('changeInternalAffectation', 'Chantier');
teamLeadQualiteBuilder.can('useAdvancedActions', 'Chantier');

teamLeadQualiteBuilder.can('manage', 'Company');
teamLeadQualiteBuilder.cannot('manage', 'Company', ['isCommission']);

teamLeadQualiteBuilder.can('cancelAppelDePaiement', 'Chantier');
teamLeadQualiteBuilder.can('changeAmountAppelDePaiement', 'Chantier');
teamLeadQualiteBuilder.can('manage', 'Partner');
teamLeadQualiteBuilder.can('libererFonds', 'Chantier');
teamLeadQualiteBuilder.can('validateAppelDePaiement', 'Chantier');
teamLeadQualiteBuilder.can('activateSuiviRenforce', 'Chantier');

export const teamLeadQualite = teamLeadQualiteBuilder.build();
