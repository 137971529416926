import React from 'react';

import { SearchCompany } from 'features/Entreprises/features/SearchCompany';
import { CompanyType, ConsultationInfo, Consultation } from 'types';

import { ConsultationActions } from './ConsultationActions';

type Props = {
  companyTypes?: CompanyType[];
  consultationInfo: ConsultationInfo;
  consultations: Consultation[];
};

export const ConsultationManual: React.FC<Props> = ({
  consultationInfo,
  companyTypes,
  consultations,
}) => (
  <SearchCompany
    initialFormValues={{
      acceptsProjects: consultationInfo.isBarnes ? 'barnes' : 'hemea',
      companyTypes,
    }}
    enableCompanyTypeAndStatus={false}
    renderActions={proCompany => (
      <ConsultationActions
        proCompany={proCompany}
        consultationInfo={consultationInfo}
        consultations={consultations}
      />
    )}
  />
);
