import React from 'react';

import { useFormState } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { StepField } from '@travauxlib/shared/src/lib/GodMode/gdm_steps/BaseStep';
import { required } from '@travauxlib/shared/src/utils/form';

type Props = {
  field: StepField;
};

export const Field: React.FC<Props> = ({ field }) => {
  const { values } = useFormState();
  if (field.hide?.(values)) {
    return null;
  }

  if (field.fieldType === 'checkbox') {
    return (
      <CheckboxField
        validate={field.required ? required : undefined}
        initialValue={field.initialValue}
        className=""
        key={field.name}
        label={field.name}
        name={field.name}
        id={field.name}
      />
    );
  }

  if (field.fieldType === 'dropdown') {
    return (
      <DropdownField
        validate={field.required ? required : undefined}
        initialValue={field.initialValue}
        options={field.options || []}
        key={field.name}
        label={field.label || field.name}
        name={field.name}
        id={field.name}
      />
    );
  }

  if (field.fieldType === 'numberInput') {
    return (
      <NumberInputField
        validate={field.required ? required : undefined}
        initialValue={field.initialValue}
        className=""
        key={field.name}
        label={field.label || field.name}
        name={field.name}
        id={field.name}
        helperText={field.helperText}
      />
    );
  }

  return (
    <InputField
      validate={field.required ? required : undefined}
      initialValue={field.initialValue}
      className=""
      key={field.name}
      label={field.label || field.name}
      name={field.name}
      id={field.name}
      helperText={field.helperText}
    />
  );
};
