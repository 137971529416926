import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { RadioButtonsList } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { safeFormat } from '@travauxlib/shared/src/utils/time';

import { useIsAllowed } from 'hooks/useIsAllowed';

import { FluxFinanciersHistoriqueItem } from './FluxFinanciersHistoriqueItem';

import { useValidateAppelDePaiement } from '../../api/useValidateAppelDePaiement';
import { useCancelAppelDePaiementModal } from '../../hooks/useCancelAppelDePaiementModal';
import { useCancelSignatureModal } from '../../hooks/useCancelSignatureModal';
import { useChangeTransactionVisibilityModal } from '../../hooks/useChangeTransactionVisibilityModal';
import { useIgnoreTransactionModal } from '../../hooks/useIgnoreTransactionModal';
import { useModifyAppelDePaiementModal } from '../../hooks/useModifyAppelDePaiementModal';
import { useValidateAppelDePaiementModal } from '../../hooks/useValidateAppelDePaiementModal';
import { FluxFinancierHistoriqueFilterType, HistoryItem } from '../../types';
import { appelDePaiementToHistoryItem } from '../../utils/appelDePaiementToHistoryItem';
import { appelDeProvisionToHistoryItem } from '../../utils/appelDeProvisionToHistoryItem';
import { paiementToHistoryItem } from '../../utils/paiementToHistoryItem';
import { provisionToHistoryItem } from '../../utils/provisionToHistoryItem';
import { signatureToHistoryItem } from '../../utils/signatureToHistoryItem';

type Props = {
  chantier: Chantier;
};

export const FluxFinanciersHistorique: React.FC<Props> = ({ chantier }) => {
  const [fluxFinanciersFilter, setFluxFinanciersFilter] =
    useState<FluxFinancierHistoriqueFilterType>(FluxFinancierHistoriqueFilterType.All);
  const isTabletOrMobile = useIsTabletOrMobile();

  const { signatures, transactions, appelsDeProvision, appelsDePaiement } = chantier;
  const isAllowedToCancelSignature = useIsAllowed('Chantier', 'cancelSignature');
  const isAllowedToManageTransactions = useIsAllowed('Chantier', 'ignoreTransaction');
  const isAllowedToCancelAppelDePaiement = useIsAllowed('Chantier', 'cancelAppelDePaiement');
  const isAllowedToModifyAppelDePaiement = useIsAllowed('Chantier', 'changeAmountAppelDePaiement');
  const isAllowedToValidateAppelDePaiement = useIsAllowed('Chantier', 'validateAppelDePaiement');
  const { isLoading } = useValidateAppelDePaiement();

  const handleOpenModalCancelAppelDePaiement = useCancelAppelDePaiementModal();
  const handleOpenModalCancelSignature = useCancelSignatureModal();
  const handleOpenModalIgnoreTransaction = useIgnoreTransactionModal();
  const handleOpenModalChangeTransactionVisibility = useChangeTransactionVisibilityModal();
  const handleOpenModifyAppelDePaiementModal = useModifyAppelDePaiementModal();
  const handleOpenValidateAppelDePaiementModal = useValidateAppelDePaiementModal();

  const provisionHistoryItem = provisionToHistoryItem(
    handleOpenModalIgnoreTransaction,
    handleOpenModalChangeTransactionVisibility,
    chantier,
    isAllowedToManageTransactions,
  );

  const paiementHistoryItem = paiementToHistoryItem(
    handleOpenModalIgnoreTransaction,
    handleOpenModalChangeTransactionVisibility,
    chantier,
    isAllowedToManageTransactions,
  );

  const unfilteredHistoryItems: HistoryItem[] = signatures
    .map(s =>
      signatureToHistoryItem(
        s,
        transactions,
        handleOpenModalCancelSignature,
        isAllowedToCancelSignature,
      ),
    )
    .concat(
      transactions.map(transaction =>
        transaction.amount >= 0
          ? provisionHistoryItem(transaction)
          : paiementHistoryItem(transaction),
      ),
    )
    .concat(appelsDeProvision.map(appelDeProvisionToHistoryItem))
    .concat(
      appelsDePaiement.map(a =>
        appelDePaiementToHistoryItem({
          appelDePaiement: a,
          chantier,
          handleOpenModalCancelAppelDePaiement,
          isLoading,
          handleOpenModifyAppelDePaiementModal,
          isAllowedToCancelAppelDePaiement,
          isAllowedToModifyAppelDePaiement,
          isAllowedToValidateAppelDePaiement,
          handleOpenValidateAppelDePaiementModal,
        }),
      ),
    );

  const historyItems: { date: string; items: HistoryItem[] }[] = useMemo(() => {
    const filteredHistoryItems = unfilteredHistoryItems.filter(item =>
      ['all', item.filterType].includes(fluxFinanciersFilter),
    );

    const groupedLines = _groupBy(filteredHistoryItems, item =>
      dayjs(item.date).startOf('day').format('YYYY-MM-DD'),
    );

    return _orderBy(
      Object.entries(groupedLines).map(([date, items]) => ({
        date,
        items: _orderBy(items, [item => dayjs(item.date).valueOf()], ['desc']),
      })),
      [item => dayjs(item.date).valueOf()],
      ['desc'],
    );
  }, [fluxFinanciersFilter, unfilteredHistoryItems]);

  return (
    <Card className="mt-md" state="outlined">
      <div className="flex sm-desktop:items-center sm-desktop:flex-row flex-col justify-between mb-md">
        <div className="text-h5 font-bold mb-xs sm-desktop:mb-0">Historique</div>
        <RadioButtonsList<FluxFinancierHistoriqueFilterType>
          value={fluxFinanciersFilter}
          onChange={setFluxFinanciersFilter}
          inline={!isTabletOrMobile}
          options={[
            { label: 'Tout', value: FluxFinancierHistoriqueFilterType.All },
            {
              label: 'Paiement',
              value: FluxFinancierHistoriqueFilterType.Paiement,
            },
            {
              label: 'Provision',
              value: FluxFinancierHistoriqueFilterType.Provision,
            },
            {
              label: 'Signature',
              value: FluxFinancierHistoriqueFilterType.Signature,
            },
          ]}
        />
      </div>
      {historyItems.map(({ date, items }) => (
        <div role="historyItemsByDate" key={date} className="mb-xs">
          <div className="mb-xs first-letter:uppercase text-b1 font-medium">
            {safeFormat(date, 'dddd DD MMMM YYYY')}
          </div>
          <Card state="outlined" bodyClassNames="!p-0">
            {items.map(item => (
              <FluxFinanciersHistoriqueItem key={item.key} historyItem={item} />
            ))}
          </Card>
        </div>
      ))}
    </Card>
  );
};
