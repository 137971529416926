import React from 'react';

import { useNavigate } from 'react-router-dom';

import DotsMenuHorizontal from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenuHorizontal.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { getCycleActions } from '../../utils/getCycleActions';
import { getCycleConfig } from '../../utils/getCycleConfigEtatAvancement';

type Props = {
  items: SuiviChantierCycleHistory[];
  chantier: Chantier;
  handleOpenModalChangeTransactionVisibility: (uuid: string, chantierSlug: string) => void;
  isAllowedToManageSuiviChantier: boolean;
};

export const CardsEtatAvancement: React.FC<Props> = ({
  items,
  chantier,
  handleOpenModalChangeTransactionVisibility,
  isAllowedToManageSuiviChantier,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-md">
      {items.map((cycle, index) => {
        const { progression, renderMontantPercu } = getCycleConfig(cycle);

        return (
          <Card role="card" state="outlined" key={index} className="relative">
            <div className="absolute top-xs right-xs">
              <ActionList
                small
                trigger={(toggle, referenceProps) => (
                  <IconButton
                    onClick={toggle}
                    aria-label="Actions"
                    data-testid={`cycle-actions-${cycle.uuid}`}
                    className="!p-0 !rounded-full !hover:bg-neutral-100 !hover:text-neutral-500"
                    {...referenceProps}
                  >
                    <DotsMenuHorizontal />
                  </IconButton>
                )}
                items={getCycleActions(
                  cycle,
                  chantier.slug,
                  navigate,
                  handleOpenModalChangeTransactionVisibility,
                  isAllowedToManageSuiviChantier,
                ).map(item => ({
                  ...item,
                  type: 'action',
                  onClick: item.action,
                }))}
              />
            </div>
            <div className="mb-xs">
              <span className="font-semibold">{formatFrenchDate(cycle.createdAt)}</span>
            </div>
            <div className="mb-xs">
              <div className="flex justify-between text-sm text-neutral-600 mb-3xs">
                <span>Avancé</span>
                <span className="font-medium">{progression}%</span>
              </div>
              <ProgressBar value={progression} />
            </div>
            <div className="flex justify-between text-b2">
              <span>Montant demandé:</span>
              <EURCurrency className="font-medium" amount={cycle.etapes.pro.montantALiberer} />
            </div>
            <div className="flex justify-between text-b2">
              <span>Montant perçu:</span>
              <span>{renderMontantPercu()}</span>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
