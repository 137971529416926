import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { ColumnConfig } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { adminUrls } from 'utils/urls';

import { getCycleActions } from '../../utils/getCycleActions';
import { getCycleConfig } from '../../utils/getCycleConfigEtatAvancement';

const columnConfig: Array<ColumnConfig<SuiviChantierCycleHistory>> = [
  {
    name: 'Date',
    columnKey: 'createdAt',
    renderValue: (d: string, cycle) => (
      <div className="flex items-center relative">
        {cycle.status === 'pending' && (
          <Badge className="-left-sm absolute" size="xs" variant="error" value={1} />
        )}
        {formatFrenchDate(d)}
      </div>
    ),
  },
  {
    name: 'Progression',
    renderValue: (_, cycle) => {
      const { progression } = getCycleConfig(cycle);
      return <>{progression}</>;
    },
    hideSort: true,
  },
  {
    name: 'Montant demandé',
    renderValue: (_, cycle) => <EURCurrency amount={cycle.etapes.pro.montantALiberer} />,
    hideSort: true,
  },
  {
    name: 'Montant perçu',
    renderValue: (_, cycle) => {
      const { renderMontantPercu } = getCycleConfig(cycle);
      return <>{renderMontantPercu()}</>;
    },
    hideSort: true,
  },
];

export type Props = {
  items: SuiviChantierCycleHistory[];
  chantier: Chantier;
  handleOpenModalChangeTransactionVisibility: (uuid: string, chantierSlug: string) => void;
  isAllowedToManageSuiviChantier: boolean;
};

export const TableEtatAvancement: React.FC<Props> = ({
  items,
  chantier,
  handleOpenModalChangeTransactionVisibility,
  isAllowedToManageSuiviChantier,
}) => {
  const navigate = useNavigate();

  return (
    <Table<SuiviChantierCycleHistory>
      columnConfigs={columnConfig}
      items={items}
      defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
      onRowClick={history => navigate(adminUrls.suiviChantierHistoryUrl(history.uuid))}
      rowActions={cycle =>
        getCycleActions(
          cycle,
          chantier.slug,
          navigate,
          handleOpenModalChangeTransactionVisibility,
          isAllowedToManageSuiviChantier,
        )
      }
    />
  );
};
