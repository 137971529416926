import React from 'react';

import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { ConsultationInfo, ProCompany, Consultation } from 'types';

import { DisabledReasonsTooltip } from './DisabledReasonsTooltip';

import { useOpenConsultationModal } from '../ConsultationModal';
import { ConsultationWarningsTooltip } from '../ConsultationWarning';

type Props = {
  consultationInfo: ConsultationInfo;
  proCompany: ProCompany;
  proCompanies?: ProCompany[];
  consultations: Consultation[];
};

export const getDisabledReasons = ({
  proCompany,
  consultations,
}: {
  proCompany: ProCompany;
  consultations: Consultation[];
}): string[] => {
  const reasons = [];

  if (proCompany.frozenAt) {
    reasons.push('Cette entreprise est gelée.');
  }

  if (proCompany.pauseReason) {
    reasons.push('Cette entreprise est en pause.');
  }

  if (
    consultations.find(
      c => c.consultationOffer.proCompanyId === proCompany.id && c.consultationOffer.isPending,
    )
  ) {
    reasons.push(
      "Cette entreprise a déjà reçu une consultation pour ce projet, qu'elle doit encore accepter ou refuser.",
    );
  }

  if (
    consultations.find(
      c => c.consultationOffer.proCompanyId === proCompany.id && c.consultationOffer.isAccepted,
    )
  ) {
    reasons.push('Cette entreprise a déjà accepté une consultation pour ce deal Pipedrive.');
  }

  return reasons;
};

export const ConsultationActions: React.FC<Props> = ({
  proCompany,
  consultationInfo,
  proCompanies,
  consultations,
}) => {
  const openConsultationModal = useOpenConsultationModal();
  const disabledReasons = getDisabledReasons({
    proCompany,
    consultations,
  });

  return (
    <>
      <div className="!text-center">
        <DisabledReasonsTooltip disabledReasons={disabledReasons} proCompanyUuid={proCompany.uuid}>
          <Button
            type="button"
            variant="secondary"
            className="!flex !items-center !mb-xs"
            disabled={disabledReasons.length > 0}
            onClick={() =>
              openConsultationModal({
                selectedProCompanies: [proCompany],
                proCompanies,
                consultationInfo,
              })
            }
            leftIcon={<OpenTab />}
            fullwidth
          >
            Choisir cette entreprise...
          </Button>
        </DisabledReasonsTooltip>
      </div>
      <ConsultationWarningsTooltip consultationInfo={consultationInfo} proCompany={proCompany} />
    </>
  );
};
