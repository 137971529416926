import React, { useState } from 'react';

import { ProCompany } from 'types';

import { CompanyPagination, DEFAULT_COMPANIES_DISPLAYED } from './CompanyPagination';
import { Ligne } from './Ligne';
import { TableHeader } from './TableHeader';

type Props = {
  proCompanies: ProCompany[];
  renderActions?: (proCompany: ProCompany) => JSX.Element;
};

export const Table: React.FC<Props> = ({ proCompanies, renderActions }) => {
  const [nbProCompaniesDisplayed, setNbProCompaniesDisplayed] = useState(
    DEFAULT_COMPANIES_DISPLAYED,
  );

  const slicedProCompanies = proCompanies.slice(0, nbProCompaniesDisplayed);

  return (
    <>
      <h1>
        {proCompanies.length} Entreprises ({slicedProCompanies.length} affichées)
      </h1>

      <div className="overflow-x-scroll">
        <table className="table block w-full max-h-[500px] table-bordered table-striped">
          <TableHeader />
          <tbody>
            {slicedProCompanies.map(proCompany => (
              <Ligne key={proCompany.slug} proCompany={proCompany} renderActions={renderActions} />
            ))}
          </tbody>
        </table>
      </div>
      <CompanyPagination
        proCompanies={proCompanies}
        slicedProCompanies={slicedProCompanies}
        nextPage={() =>
          setNbProCompaniesDisplayed(
            nbProCompaniesDisplayed => nbProCompaniesDisplayed + DEFAULT_COMPANIES_DISPLAYED,
          )
        }
      />
    </>
  );
};
