import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

import { ProjectAdminDevisProCompanyView } from '../types';

export const handleAdminProjectDevis: (
  projectUuid: string,
) => () => Promise<{ data: ProjectAdminDevisProCompanyView[] }> = (projectUuid: string) => () => {
  const url = `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/devis`;
  return request(url);
};

export const useAdminProjectDevis: (projectUuid: string) => {
  data: ProjectAdminDevisProCompanyView[] | undefined;
  isLoading: boolean;
} = (
  projectUuid: string,
): { data: ProjectAdminDevisProCompanyView[] | undefined; isLoading: boolean } => {
  const { isLoading, data } = useQuery<{ data: ProjectAdminDevisProCompanyView[] }>({
    queryKey: [PROJECT_KEY, projectUuid, 'devis'],
    queryFn: handleAdminProjectDevis(projectUuid),
  });

  return { isLoading, data: data?.data };
};
