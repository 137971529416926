import React from 'react';

import { Navigate } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { LeadAssignmentConfig, Person } from '@travauxlib/shared/src/types';

import { useAttribuableEmployees } from 'features/Trombinoscope/api/usePersons';
import { useIsAllowed } from 'hooks/useIsAllowed';

import { EditAssignmentConfigForm } from './EditAssignmentConfigForm';

export const EditAssignmentConfig: React.FC = () => {
  const {
    persons: attribuableEmployees,
    createOrUpdatePerson,
    isLoading,
  } = useAttribuableEmployees();

  const handleAdminSubmit = (person: Person) => (leadAssignment: LeadAssignmentConfig) =>
    createOrUpdatePerson({
      ...person,
      config: {
        ...person.config,
        leadAssignment,
      },
    });

  const isAllowedToUpdateAttributionAuto = useIsAllowed('Lead', 'manageAttributionRules');

  if (isLoading) {
    return <Loader />;
  }

  if (!isAllowedToUpdateAttributionAuto) {
    return <Navigate replace to=".." />;
  }

  return (
    <>
      <h3>Configuration de l'attribution automatique des leads</h3>
      <div className="flex flex-wrap -mx-md">
        {attribuableEmployees.map((person, i) => (
          <React.Fragment key={person.uuid}>
            <div className="w-4/12 px-md relative !p-sm">
              <div className="border border-solid border-gray-300 !rounded !bg-white !shadow-xs !p-sm !h-full">
                <EditAssignmentConfigForm
                  displayName={`${person.firstName} ${person.lastName}`}
                  picture={person.officialPicture}
                  config={person.config.leadAssignment}
                  onSubmit={handleAdminSubmit(person)}
                />
              </div>
            </div>
            {(i + 1) % 3 === 0 && <div className="!w-full" />}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
