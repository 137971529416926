import React, { useEffect } from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  ModalContextWrapper,
  ModalRoot,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal/ModalRoot';
import { DevisHistoryContextWrapper } from '@travauxlib/shared/src/components/DevisHistoryContext';
import { ToastContainer } from '@travauxlib/shared/src/components/Notifications';

import { useAuth } from 'api/useAuth';
import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { IdentityTracking } from 'utils/tracking/IdentityTracking';

import { AdminRoutes } from './routes';

export const App: React.FC = () => {
  const { isLoading, adminUserProfile } = useAuth();

  useEffect(() => {
    if (adminUserProfile.uuid) {
      IdentityTracking.identify(adminUserProfile);
    }
  }, [adminUserProfile?.uuid]);

  if (isLoading) {
    return <Loader isFullHeightScreen />;
  }

  return (
    <ModalContextWrapper>
      <DevisHistoryContextWrapper>
        <PageTitle title={PageTitleEnum.defaultTitle} />
        <ToastContainer />
        <ModalRoot />
        <div className="flex flex-col min-h-dvh">
          <AdminRoutes adminEmail={adminUserProfile.adminEmail} />
        </div>
      </DevisHistoryContextWrapper>
    </ModalContextWrapper>
  );
};
