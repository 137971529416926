import React, { useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { RadioButtonsList } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList';
import { FileUpload } from '@travauxlib/shared/src/components/FileUpload';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { toast } from '@travauxlib/shared/src/components/Notifications';

import { apiUrlsBuilder } from 'utils/urls';

import { useUploadBaseDePrix } from '../api/useUploadBaseDePrix';

type Props = {
  slug: string;
};

type OriginType = 'batiprix';

export const BaseDePrix: React.FC<Props> = ({ slug }) => {
  const { isLoading, uploadBaseDePrix } = useUploadBaseDePrix();
  const [file, setFile] = useState<File>();
  const [archiveExisting, setArchiveExisting] = useState(false);
  const [origin, setOrigin] = useState<OriginType>();
  return (
    <div className="flex flex-wrap -mx-md">
      <div className="w-4/12 px-md relative">
        <FormLabel label="Uploader une base de prix" />
        <Link href={apiUrlsBuilder.proCompanies.baseDePrixCsv(slug)} download>
          Télécharger la base existante
        </Link>
        <FileUpload
          id="upload-bdp"
          label="Parcourir..."
          onChange={setFile}
          onReject={rejectionMessage => toast.error(rejectionMessage)}
        />
        {file && (
          <div className="!pt-lg">
            <RadioButtonsList
              className="mb-md"
              inline
              value={archiveExisting}
              options={[
                {
                  label: 'Ajouter',
                  value: false,
                },
                {
                  label: 'Remplacer',
                  value: true,
                },
              ]}
              onChange={(value = archiveExisting) => setArchiveExisting(value)}
            />
            <RadioButtonsList<'batiprix' | undefined>
              className="mb-md"
              inline
              value={origin}
              options={[
                {
                  label: 'hemea',
                  value: undefined,
                },
                {
                  label: 'Batiprix',
                  value: 'batiprix',
                },
              ]}
              onChange={value => setOrigin(value)}
            />
            {isLoading && <Loader />}
            <Button
              type="button"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => uploadBaseDePrix({ slug, file, archiveExisting, origin })}
            >
              Valider l'import
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
