import { ConsultationInfoView } from '@travauxlib/shared/src/types/api/admin/ConsultationInfoView';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { ConsultationAdminView } from '@travauxlib/shared/src/types/api/domain/miseenrelation/ConsultationAdminView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { proCompany?: ProCompanyAdminView; consultationInfo: ConsultationInfoView };

export class CreateConsultation extends BaseStep<
  Input,
  { mer: ConsultationAdminView },
  StepField[],
  { mer: ConsultationAdminView }
> {
  public name: string = 'Create consultation';

  public fields = [];

  async execute({
    proCompany,
    consultationInfo,
    apiURL,
    request,
  }: ExecuteParams<Input>): Promise<{ mer: ConsultationAdminView } | undefined> {
    if (!proCompany) {
      super.error('No proCompany found. See stack !');
      return;
    }

    if (!proCompany.proUsers[0].id) {
      super.error('No proUser found in proCompany. See stack !');
      return;
    }

    const consultationOffer = await request<ConsultationAdminView>(
      `${apiURL}/admin/consultations/${consultationInfo.id}/consultation-offer`,
      {
        method: 'POST',
        body: {
          proUserId: proCompany.proUsers[0].id,
          merData: {
            proCompanies: [],
          },
          dureeValiditeHeures: 99999,
          withRdv: false,
          proCompanyUuidsNotTested: [],
        },
      },
    );

    super.log('OK');

    super.setCreatedEntities({ mer: consultationOffer });

    return { mer: consultationOffer };
  }
}
