import React from 'react';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/EnvelopeOutline.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';

import { useAuth } from 'api/useAuth';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { ChaleurLead, Lead, LeadStatus, NonAdressableReason } from 'types';
import {
  chaleursLead,
  leadRefuse,
  nonAdressableReasons,
  statusAdressable,
  statusATraiter,
  statusNonAdressable,
} from 'utils/constants';

import { AdressableButton } from './AdressableButton';

import { useAutoAssignCDPL } from '../api/useAutoAssignCDPL';

type Props = {
  lead: Lead;
  handleUpdateAdressable: (payload: { lead: Lead; chaleurLead: ChaleurLead }) => void;
  handleCloseDemande: (payload: { lead: Lead; nonAdressableReason: NonAdressableReason }) => void;
  handleUpdateStatus: (payload: {
    lead: Lead;
    status?: LeadStatus;
    nonAdressableReason?: NonAdressableReason;
  }) => Promise<Lead>;
  isUpdating: boolean;
};

export const LeadUpdater: React.FC<Props> = ({
  lead,
  handleCloseDemande,
  handleUpdateStatus,
  handleUpdateAdressable,
  isUpdating,
}) => {
  const {
    adminUserProfile: { adminEmail },
  } = useAuth();

  const isAllowedActionsOnThisLead = useIsAllowed(
    'Lead',
    'manage',
    lead.dealOwner === adminEmail ? ['isAssignedTo'] : [],
  );

  const handleAutoAssignCDPL = useAutoAssignCDPL();
  const actualNonAdressableReasons = [
    ...nonAdressableReasons,
    // useful if the reasons are renamed in Pipedrive and we want to be backward compatible
    ...(lead.nonAdressableReason &&
    nonAdressableReasons.every(({ value }) => value !== lead.nonAdressableReason)
      ? [
          {
            value: lead.nonAdressableReason,
            label: lead.nonAdressableReason,
          },
        ]
      : []),
  ];

  const { status: leadStatus, acquisitionPrice } = lead;
  const isATraiter = leadStatus === statusATraiter;
  const isNonAdressable = leadStatus === statusNonAdressable;

  return (
    <>
      <div className="inline-flex mb-md">
        {[statusATraiter, statusAdressable].map(status => (
          <Button
            type="button"
            key={lead.token + status}
            variant={status === leadStatus ? 'primary' : 'secondary'}
            onClick={() => handleUpdateStatus({ lead, status })}
            disabled={isUpdating || !isAllowedActionsOnThisLead}
            loading={isUpdating}
          >
            {status}
          </Button>
        ))}
      </div>
      {(isNonAdressable || !lead.dealOwner) && (
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            handleAutoAssignCDPL({
              lead,
            });
          }}
          disabled={isUpdating || !isAllowedActionsOnThisLead}
          loading={isUpdating}
        >
          Attribuer à un CDPL
        </Button>
      )}
      {isNonAdressable && (
        <div>
          <Dropdown
            id="non-adressable-reason"
            label="Raison non-adressable"
            value={lead.nonAdressableReason}
            disabled={!isAllowedActionsOnThisLead}
            options={actualNonAdressableReasons}
            onChange={value =>
              handleUpdateStatus({
                lead,
                nonAdressableReason: value as NonAdressableReason,
              })
            }
          />
        </div>
      )}

      {isATraiter && (
        <div className="inline-flex flex-col items-start justify-center">
          {acquisitionPrice ? (
            <Button
              type="button"
              onClick={() => handleCloseDemande({ lead, nonAdressableReason: leadRefuse })}
              variant="secondary"
              disabled={isUpdating || !isAllowedActionsOnThisLead}
              loading={isUpdating}
            >
              Refuser le lead
            </Button>
          ) : (
            nonAdressableReasons
              .filter(reason => !reason.disabled)
              .map(nonAdressableReason => (
                <Button
                  variant="tertiary"
                  key={lead.token + nonAdressableReason.value}
                  type="button"
                  data-testid="button-non-adressable"
                  className="w-full"
                  onClick={() =>
                    handleCloseDemande({
                      lead,
                      nonAdressableReason: nonAdressableReason.value,
                    })
                  }
                  leftIcon={<Envelope />}
                  disabled={isUpdating || !isAllowedActionsOnThisLead}
                  loading={isUpdating}
                >
                  {nonAdressableReason.label}
                </Button>
              ))
          )}
        </div>
      )}
      {lead.status === statusAdressable && (
        <div>
          <div className="inline-flex mb-md" role="group">
            {chaleursLead.map(chaleurLead => (
              <AdressableButton
                handleUpdateAdressable={handleUpdateAdressable}
                key={chaleurLead}
                lead={lead}
                chaleurLead={chaleurLead}
                active={lead.chaleurLead === chaleurLead}
                loading={isUpdating}
                disabled={isUpdating || !isAllowedActionsOnThisLead}
              >
                {chaleurLead}
              </AdressableButton>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
