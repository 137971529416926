import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { useAuth, AUTH_KEY } from 'api/useAuth';
import { useLogoutFromClientAccount } from 'api/useLogoutFromClientAccount';
import { useLogoutFromProAccount } from 'api/useLogoutFromProAccount';

export const Warnings: React.FC = () => {
  const {
    adminUserProfile: { isConnectedAsPro, isConnectedAsClient },
  } = useAuth();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
  }, []);

  const logoutFromPro = useLogoutFromProAccount();
  const logoutFromClient = useLogoutFromClientAccount();

  return (
    <div className="mb-md">
      {isConnectedAsPro && (
        <AlertMessage className="mb-xs" level="warning">
          <span className="mr-xs">
            Vous êtes connecté en tant que pro! Cela peut faire échouer le flow.
          </span>
          <Button onClick={logoutFromPro} size="sm">
            Se déconnecter
          </Button>
        </AlertMessage>
      )}
      {isConnectedAsClient && (
        <AlertMessage className="mb-xs" level="warning">
          <span className="mr-xs">
            Vous êtes connecté en tant que client! Cela peut faire échouer le flow.
          </span>
          <Button onClick={logoutFromClient} size="sm">
            Se déconnecter
          </Button>
        </AlertMessage>
      )}
    </div>
  );
};
