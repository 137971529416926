import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

const handleToggleConformiteDevis =
  (projectUuid: string) =>
  ({ newValue }: { newValue: boolean }) =>
    request(
      `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/toggle-conformite-devis/${newValue}`,
      {
        method: 'POST',
      },
    );
export const useToggleConformiteDevis = (
  projectUuid: string,
): ((variables: { newValue: boolean }) => Promise<any>) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleToggleConformiteDevis(projectUuid),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY, projectUuid] });
    },
  });
  return mutateAsync;
};
