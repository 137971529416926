import React from 'react';

import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { LabellisationStatus } from '@travauxlib/shared/src/types/company';

import { ConsultationInfo, ProCompany } from 'types';

import { CompanyDetails, computeHasSlotsInTheFuture } from './CompanyDetails';

import { useCreateConsultationOffer } from '../../api/useCreateConsultationOffer';

export const getInitialValues = (
  proCompany: ProCompany,
): {
  [K: string]: {
    proUserId?: number;
    dureeValiditeHeures: number;
    status: LabellisationStatus;
  };
} => {
  const proUser = proCompany.proUsers.length === 1 ? proCompany.proUsers[0] : undefined;
  const hour = new Date().getHours();

  return {
    [proCompany.uuid]: {
      proUserId: proUser?.id,
      dureeValiditeHeures: hour >= 6 && hour < 16 ? 6 : 20,
      status: proCompany.status,
    },
  };
};

type FormData = {
  [K: string]: {
    proUserId: number;
    dureeValiditeHeures: number;
    withNoRdv?: boolean;
    status: LabellisationStatus;
  };
};

type Props = {
  consultationInfo: ConsultationInfo;
  selectedProCompanies: ProCompany[];
  proCompanies?: ProCompany[];
  handleClose: () => void;
  companyUuidsNotDisabled?: string[];
};

export const ConsultationModal: React.FC<Props> = ({
  consultationInfo,
  selectedProCompanies,
  proCompanies,
  handleClose,
  companyUuidsNotDisabled,
}) => {
  const createConsultationOffer = useCreateConsultationOffer();
  const initialValues = selectedProCompanies.reduce(
    (previousValue, currentValue) => ({ ...previousValue, ...getInitialValues(currentValue) }),
    {},
  );

  const merData = {
    proCompanies:
      proCompanies?.map(({ id, status, score: { totalScore: score, ...rest } }, index) => ({
        proCompanyId: id,
        status,
        score,
        rank: index + 1,
        ...rest,
      })) || [],
  };

  const navigate = useNavigate();

  return (
    <Form<FormData>
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={async values => {
        const selectedProCompanyUuids = Object.keys(values);
        const hasOneCompanyToTestBeenSelected = Object.values(values).some(
          proCompanyInfo => proCompanyInfo.status === LabellisationStatus.labellisee,
        );

        const proCompanyUuidsNotTested =
          proCompanies && !hasOneCompanyToTestBeenSelected
            ? proCompanies
                .filter(
                  proCompany =>
                    proCompany.status === LabellisationStatus.labellisee &&
                    companyUuidsNotDisabled?.includes(proCompany.uuid) &&
                    !selectedProCompanyUuids.includes(proCompany.uuid),
                )
                .map(proCompany => proCompany.uuid)
            : [];

        await Promise.all(
          Object.entries(values).map(
            async ([proCompanyUuid, { proUserId, dureeValiditeHeures, withNoRdv }], index) => {
              const rank =
                proCompanies &&
                `${proCompanies.findIndex(pc => pc.uuid === proCompanyUuid) + 1}/${
                  proCompanies.length
                }`;

              return createConsultationOffer({
                consultationInfoId: consultationInfo.id,
                proUserId,
                merData,
                rank,
                dureeValiditeHeures,
                withRdv: !withNoRdv,
                proCompanyUuidsNotTested: index === 0 ? proCompanyUuidsNotTested : [],
              });
            },
          ),
        );

        handleClose();
        navigate(`/projects/${consultationInfo.projectUuid}/consultation`);
      }}
    >
      {({ handleSubmit, invalid, values, submitting }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Envoyer',
            type: 'submit',
            disabled:
              invalid ||
              submitting ||
              Object.values(values).some(({ withNoRdv }) => {
                const hasSlotsInTheFuture = computeHasSlotsInTheFuture(
                  consultationInfo.slotsRendezVous,
                );

                return !hasSlotsInTheFuture && !withNoRdv;
              }),
            loading: submitting,
          }}
          cancelAction={{ label: 'Annuler', onClick: handleClose }}
        >
          <div className="flex justify-center flex-wrap gap-md">
            {selectedProCompanies.map(selectedProCompany => (
              <div className="flex flex-col flex-grow" key={selectedProCompany.uuid}>
                <CompanyDetails
                  proCompany={selectedProCompany}
                  slotsRendezVous={consultationInfo.slotsRendezVous}
                />
              </div>
            ))}
          </div>
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenConsultationModal = () => {
  const openModal = useOpenModal();

  return (props: Omit<Props, 'handleClose'>) =>
    openModal(ConsultationModal, {
      title: 'Choisir cette entreprise',
      size: 'lg',
      ...props,
    });
};
