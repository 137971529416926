import React from 'react';

import AlertCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AlertCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { ProCompany } from 'types';

export const DEFAULT_COMPANIES_DISPLAYED = 50;

type Props = {
  proCompanies: ProCompany[];
  slicedProCompanies: ProCompany[];
  nextPage: () => void;
};

export const CompanyPagination: React.FC<Props> = ({
  proCompanies,
  slicedProCompanies,
  nextPage,
}) => {
  const nbCompaniesNotDisplayed = proCompanies.length - slicedProCompanies.length;

  return (
    <div className="!text-center !py-lg">
      {nbCompaniesNotDisplayed > 0 ? (
        <>
          <p>
            <AlertCircle className="w-md h-md text-warning" />
            <span className="!mx-xs">
              {slicedProCompanies.length} entreprises sur {proCompanies.length} sont affichées.
            </span>
            <AlertCircle className="w-md h-md text-warning" />
          </p>
          <Button variant="tertiary" type="button" onClick={nextPage}>
            {nbCompaniesNotDisplayed > DEFAULT_COMPANIES_DISPLAYED
              ? `Afficher les ${DEFAULT_COMPANIES_DISPLAYED} entreprises suivantes`
              : `Afficher les ${nbCompaniesNotDisplayed} entreprises restantes`}
          </Button>
        </>
      ) : (
        <p>Toutes les entreprises de la recherche sont affichées.</p>
      )}
    </div>
  );
};
