import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

import { PersonRole } from '@travauxlib/shared/src/types';

import { usePersons } from 'features/Trombinoscope/api/usePersons';

const defaultOptions = [
  { label: 'Tous', value: 'tous' },
  { label: 'Aucun', value: 'aucun' },
];

export const useCustomerCareAndTravauxPlannerMembers = (): { value: string; label: string }[] => {
  const { persons: attribuableEmployees, isLoading } = usePersons([
    PersonRole.TravauxPlannerInterne,
    PersonRole.TravauxPlannerExterne,
    PersonRole.CustomerCare,
    PersonRole.CDPL,
    PersonRole.TeamLeadQualite,
  ]);

  if (isLoading) {
    return [...defaultOptions];
  }

  const rawEmployeeOptions = attribuableEmployees.map(cc => ({
    label: `${cc.firstName} ${cc.lastName}`,
    value: cc.uuid,
  }));
  const employeeOptions = _sortBy(_uniqBy(rawEmployeeOptions, 'value'), 'label');

  return [...defaultOptions, ...employeeOptions];
};
