import { request } from '@travauxlib/shared/src/utils/request';

export const useSignInAsClient =
  (openEspaceClient: boolean = true) =>
  async (clientUuid: string, projectUuidToOpen?: string) => {
    const requestURL = `${APP_CONFIG.apiURL}/admin/client/${clientUuid}/sign-in`;

    await request(requestURL, { method: 'POST' });
    if (openEspaceClient) {
      window.open(
        `${APP_CONFIG.appURL}/${projectUuidToOpen ? `projets/${projectUuidToOpen}` : ''}`,
      );
    }
  };
