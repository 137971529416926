import { Person, PersonRole } from '@travauxlib/shared/src/types';

import { usePersons } from 'features/Trombinoscope/api/usePersons';

type Response = {
  allMembers: { value: string; label: string }[];
  ccMembers: { value: string; label: string }[];
  archiInterneMembers: { value: string; label: string }[];
  TPMembers: { value: string; label: string }[];
};

const formatPersons = (persons: Person[]): Array<{ label: string; value: string }> =>
  persons.map(person => ({
    label: `${person.firstName} ${person.lastName}`,
    value: person.uuid,
  }));

export const useSuiviByOptions = (): Response => {
  const { persons: ccMembers } = usePersons([PersonRole.CustomerCare]);
  const { persons: archiInterneMembers } = usePersons([PersonRole.ArchitecteInterne]);
  const { persons: TPMembers } = usePersons([
    PersonRole.TravauxPlannerInterne,
    PersonRole.TravauxPlannerExterne,
  ]);

  const { persons: allAttribuableEmployees, isLoading } = usePersons([
    PersonRole.TravauxPlannerInterne,
    PersonRole.TravauxPlannerExterne,
    PersonRole.CustomerCare,
    PersonRole.CDPL,
    PersonRole.TeamLeadQualite,
    PersonRole.ArchitecteInterne,
  ]);

  if (isLoading) {
    return { allMembers: [], ccMembers: [], archiInterneMembers: [], TPMembers: [] };
  }

  return {
    allMembers: formatPersons(allAttribuableEmployees),
    ccMembers: formatPersons(ccMembers),
    archiInterneMembers: formatPersons(archiInterneMembers),
    TPMembers: formatPersons(TPMembers),
  };
};
