import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from './useProjectContext';

export const useGetProjectOfDevis: (devisToken?: string) => {
  isLoading: false | true | boolean;
  isError: true | false;
  data: { projectUuid: string } | undefined;
} = (devisToken: string) => {
  const { isLoading, data, isError } = useQuery<{ projectUuid: string }>({
    queryKey: [PROJECT_KEY, 'fetchFromDevis', devisToken],
    queryFn: () => request(`${APP_CONFIG.apiURL}/admin/devis/${devisToken}/projectUuid`),
  });

  return { isLoading, data, isError };
};
