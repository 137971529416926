import React, { useState } from 'react';

import dayjs from 'dayjs';
import _sum from 'lodash/sum';

import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { AppelDePaiement, Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { AuthorizedGuard } from 'components/Auth/AuthorizedGuard';
import { useIsAllowed } from 'hooks/useIsAllowed';

import { eventCardClassName } from './constants';
import { LiberationFondsForm } from './LiberationFondsForm';

import { useProcessAppelDePaiement } from '../../api/useProcessAppelDePaiement';

type Props = {
  chantier: Chantier;
};

export const LibererFondsCard: React.FC<Props> = ({ chantier }) => {
  const { processAppelDePaiement } = useProcessAppelDePaiement();
  const [appelsDePaiementModalVisible, setAppelsDePaiementModalVisible] = useState(false);
  const [currentAppelDePaiement, setCurrentAppelDePaiement] = useState<AppelDePaiement>();
  const onCloseAppelsDePaiementModal = (): void => {
    setAppelsDePaiementModalVisible(false);
    setTimeout(() => setCurrentAppelDePaiement(undefined), 500);
  };

  const appelsDePaiementValides = chantier.appelsDePaiement.filter(a => a.status === 'validated');
  const isAllowedToLibererFonds = useIsAllowed('Chantier', 'libererFonds');

  return appelsDePaiementValides.length > 0 ? (
    <>
      <Modal
        isOpen={appelsDePaiementModalVisible}
        size="lg"
        handleClose={onCloseAppelsDePaiementModal}
        title="Libération de fonds"
      >
        {currentAppelDePaiement ? (
          <LiberationFondsForm
            onSubmit={formData =>
              currentAppelDePaiement &&
              processAppelDePaiement({
                payload: formData,
                chantierSlug: chantier.slug,
                uuid: currentAppelDePaiement.uuid,
              })
            }
            currentAppelDePaiement={currentAppelDePaiement}
            onClose={onCloseAppelsDePaiementModal}
            chantier={chantier}
          />
        ) : (
          <ModalContent>
            <AuthorizedGuard>
              <Table
                columnConfigs={[
                  {
                    name: 'Validation',
                    columnKey: 'validatedAt' as const,
                    renderValue: (validatedAt: string) => (
                      <span className="!whitespace-nowrap">
                        {dayjs(validatedAt).format('DD/MM/YYYY HH:mm')}
                      </span>
                    ),
                  },
                  {
                    name: 'Par',
                    columnKey: 'validatedBy' as const,
                  },
                  {
                    name: 'Montant',
                    columnKey: 'montant' as const,
                    renderValue: (montant: number) => (
                      <span className="!text-right !whitespace-nowrap">
                        <EURCurrency amount={montant} forceFullPattern />
                      </span>
                    ),
                  },
                  {
                    name: 'Action',
                    hideSort: true,
                    renderValue: (_, appelDePaiement: AppelDePaiement) => (
                      <span className="!text-center">
                        <Button
                          type="button"
                          className="!text-primary !border-0 !bg-transparent !p-0"
                          onClick={() => setCurrentAppelDePaiement(appelDePaiement)}
                        >
                          Libérer...
                        </Button>
                      </span>
                    ),
                  },
                ]}
                items={appelsDePaiementValides}
              />
            </AuthorizedGuard>
          </ModalContent>
        )}
      </Modal>
      <EventCard
        className={eventCardClassName}
        icon={<Euro />}
        title={
          <>
            Libérer&nbsp;
            <EURCurrency
              amount={_sum(appelsDePaiementValides.map(a => a.montant))}
              forceFullPattern
            />
          </>
        }
        variant="beige"
        action={{
          label: 'Libérer les fonds',
          onClick: () => setAppelsDePaiementModalVisible(true),
          disabled: !isAllowedToLibererFonds,
          hideIcon: true,
        }}
      >
        <></>
      </EventCard>
    </>
  ) : (
    <></>
  );
};
