import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { usePersons } from 'features/Trombinoscope/api/usePersons';
import { useIsAllowed } from 'hooks/useIsAllowed';

import { PersonCard } from './PersonCard';

import { useTeams } from '../api/useTeams';

type Props = {
  teamUuid?: string;
};

export const MembersList: React.FC<Props> = ({ teamUuid }) => {
  const { persons } = usePersons();
  const { teams, changeMembership } = useTeams();
  const team = teams.find(({ uuid }) => uuid === teamUuid);
  const isAllowedToManagePeoplesTeam = useIsAllowed('Person', 'manage');

  if (!team) {
    return <Loader />;
  }

  const { members } = team;
  const membersUuid = members.map(({ uuid }) => uuid);
  const nonArchivedMember = members.filter(member => !member.archived);

  return (
    <div className="container w-full lg-desktop:w-9/12 ">
      <h3 className="my-md font-bold">Membres </h3>
      <div className="grid grid-cols-10 gap-lg place-items-center">
        {nonArchivedMember.map(member => (
          <div
            key={member.uuid}
            className="col-span-full sm-desktop:col-span-3 md-desktop:col-span-2 flex flex-col items-center"
            data-testid="members"
          >
            <PersonCard to={`/trombinoscope/${member.uuid}`} person={member} />
            <Button
              variant="tertiary"
              type="button"
              size="sm"
              className="mt-md"
              disabled={!isAllowedToManagePeoplesTeam}
              disabledMessageTooltip="Autorisation nécessaire : Admin"
              onClick={() =>
                changeMembership({
                  teamUuid: team.uuid,
                  personUuid: member.uuid,
                  add: false,
                })
              }
            >
              Supprimer de l&apos;équipe
            </Button>
          </div>
        ))}
      </div>
      <hr />
      <h3 className="my-md font-bold">Non membres</h3>
      <div className="grid grid-cols-10 gap-lg place-items-center">
        {persons
          .filter(({ uuid, archived }) => !membersUuid.includes(uuid) && !archived)
          .map(member => (
            <div
              key={member.uuid}
              className="col-span-full sm-desktop:col-span-3 md-desktop:col-span-2 flex flex-col items-center"
              data-testid="non-members"
            >
              <PersonCard to={`/trombinoscope/${member.uuid}`} person={member} />
              <Button
                variant="tertiary"
                type="button"
                size="sm"
                className="mt-md"
                disabled={!isAllowedToManagePeoplesTeam}
                disabledMessageTooltip="Autorisation nécessaire : Admin"
                onClick={() =>
                  changeMembership({
                    teamUuid: team.uuid,
                    personUuid: member.uuid,
                    add: true,
                  })
                }
              >
                Ajouter dans l&apos;équipe
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
};
