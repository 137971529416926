import React, { useState } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { BadgeList } from 'components/BadgeList';
import { AvailabilityStatus } from 'features/Entreprises/components/AvailabilityStatus';
import { CompanyInfo } from 'features/Entreprises/components/CompanyInfo';
import { ConsultationList } from 'features/Entreprises/components/ConsultationList';
import { ProCompany } from 'types';

import { Comment } from './Comment';
import { LastDevis } from './LastDevis';
import { ProUsers } from './ProUsers';

type Props = {
  proCompany: ProCompany;
  renderActions?: (proCompany: ProCompany) => JSX.Element;
};

export const Ligne: React.FC<Props> = ({ proCompany, renderActions }) => {
  const [isOpenConsultation, setIsOpenConsultation] = useState(false);

  return (
    <>
      <tr
        className={classNames(
          proCompany.frozenAt && '!table-info',
          proCompany.pauseReason && 'table-warning',
        )}
        aria-labelledby={proCompany.uuid}
      >
        <td>
          <CompanyInfo withStats proCompany={proCompany} />
          <br />
          <Button
            type="button"
            onClick={() => setIsOpenConsultation(isOpenConsultation => !isOpenConsultation)}
            className="!mt-xs"
          >
            {isOpenConsultation ? 'Masquer consultations' : 'Voir dernières consultations'}
          </Button>
        </td>
        <td>
          <BadgeList badges={proCompany.corpsEtat} level="primary" />
        </td>
        <td>
          <BadgeList badges={proCompany.specializations} level="info" />
        </td>
        <td>
          <BadgeList badges={proCompany.travailEnInterne} level="light" />
        </td>
        <td>
          <BadgeList badges={proCompany.departements} level="light" />
        </td>
        <td>
          <Comment proCompany={proCompany} />
        </td>
        {proCompany.hasValidInsurances ? (
          <td className="bg-success-100">
            {proCompany.validInsurances.map((insurance, insuranceKey) => (
              <div key={insuranceKey} className="!mb-xs">
                <Link href={insurance.fileUrl} target="_blank" rel="noopener noreferrer">
                  {insurance.label} {dayjs(insurance.dateFinValidite).format('L')}
                  <br />
                </Link>
              </div>
            ))}
          </td>
        ) : (
          <td className="bg-error-100">
            <span>Pas d&apos;assurance</span>
          </td>
        )}
        <td>
          {renderActions?.(proCompany)}
          {proCompany.frozenAt && (
            <span className="!text-info !font-bold">
              <br />
              ❄️ Gelée le {formatFrenchDate(proCompany.frozenAt)}
            </span>
          )}
          <AvailabilityStatus proCompany={proCompany} advanced={false} />
          <ProUsers proCompany={proCompany} />
          {proCompany.pageVitrineUrl ? (
            <Link href={proCompany.pageVitrineUrl} target="_blank" rel="noopener noreferrer">
              Voir page vitrine
            </Link>
          ) : (
            <div>Pas de page vitrine</div>
          )}
        </td>
      </tr>
      {isOpenConsultation && (
        <tr>
          <td colSpan={8}>
            <div className="flex flex-wrap -mx-md">
              <div className="w-6/12 px-md relative">
                <ConsultationList
                  proCompany={proCompany}
                  title="lots envoyés sur les 3 derniers mois ou encore ouverts"
                />
              </div>
              <div className="w-6/12 px-md relative">
                <LastDevis proCompany={proCompany} />
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
