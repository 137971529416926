import React, { useState } from 'react';

import classNames from 'classnames';

import HandyManOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandyManOutline.svg?react';
import History from '@travauxlib/shared/src/components/DesignSystem/assets/History.svg?react';
import Lemonway from '@travauxlib/shared/src/components/DesignSystem/assets/Lemonway.svg?react';
import Notifications from '@travauxlib/shared/src/components/DesignSystem/assets/Notifications.svg?react';
import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { LiberationDesFondsHistoryModal } from '@travauxlib/shared/src/features/Chantiers/components/AccountCard/LiberationDesFondsHistoryModal';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { MontantGeleCard } from '@travauxlib/shared/src/features/SuiviChantier/components/MontantGeleCard';

import { useAuth } from 'api/useAuth';
import { useToggleRelanceProvision } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useToggleRelanceProvision';
import { SuiviFinancierRenforceModal } from 'features/Project/features/ProjectPage/features/ProjectChantier/components/SuiviFinancierRenforceModal';
import { useIsAllowed } from 'hooks/useIsAllowed';

import { Compte } from './Compte';

type Props = {
  chantier: Chantier;
  isArchiInterne: boolean;
};

const lemonwayAction = (
  accountUrl: string,
): {
  label: string;
  value: string;
  leftIcon: JSX.Element;
  onClick: () => void;
} => ({
  label: 'Lemonway',
  value: 'lemonway',
  leftIcon: <Lemonway />,
  onClick: () => {
    window.open(accountUrl, '_blank');
  },
});

export const MainComptes: React.FC<Props> = ({ chantier, isArchiInterne }) => {
  const { toggleRelanceProvision } = useToggleRelanceProvision();

  const [isSuiviFinancierRenforceModalOpen, setIsSuiviFinancierRenforceModalOpen] = useState(false);
  const [isLiberationHistoryModalOpen, setIsLiberationHistoryModalOpen] = useState(false);
  const { isLoading } = useAuth();
  const isAllowedToToggleRelance = useIsAllowed('Chantier', 'toggleRelance');
  const isAllowedToActivateSuiviRenforce = useIsAllowed('Chantier', 'activateSuiviRenforce');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className={classNames('flex flex-col grow gap-md', isArchiInterne && 'sm-desktop:!flex-row')}
    >
      <Compte
        label="Compte client"
        progressAmount={chantier.totalProvisions}
        totalAmount={chantier.montantTotalTTC}
        variant="info"
        buttonGroupActions={[
          {
            label: 'IBAN',
            value: 'iban',
            leftIcon: <OpenTab />,
            onClick: () =>
              window.open(`${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/iban-pdf`),
          },
          ...(chantier.lemonwayAccountUrl ? [lemonwayAction(chantier.lemonwayAccountUrl)] : []),
        ]}
        buttonActions={[
          {
            label: chantier.enableRelanceProvision ? 'Relance activée' : 'Relance inactive',
            leftIcon: chantier.enableRelanceProvision ? <Notifications /> : undefined,
            disabledMessageTooltip:
              'Permission requise : Autorisé à mettre en sourdine les relances de provision sur les chantiers',
            disabled: !isAllowedToToggleRelance,
            onClick: () => toggleRelanceProvision(chantier),
          },
        ]}
        amounts={[
          { label: 'Solde disponible', montant: chantier.soldeCompteSequestre },
          { label: 'Montant attendu', montant: chantier.montantProvisionsAttendues },
        ]}
      />
      {isArchiInterne ? (
        <Compte
          label="Compte archi interne"
          progressAmount={chantier.montantArchiInterneEncaisseTTC}
          totalAmount={chantier.montantTotalTTC}
          variant="success"
          buttonGroupActions={[
            {
              label: 'IBAN',
              value: 'iban',
              leftIcon: <OpenTab />,
              onClick: () =>
                window.open(`${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/iban-pdf`),
            },
            ...(chantier.hemeaLemonwayAccountUrl
              ? [lemonwayAction(chantier.hemeaLemonwayAccountUrl)]
              : []),
          ]}
          amounts={[
            { label: 'Montant versé', montant: chantier.montantArchiInterneEncaisseTTC },
            {
              label: 'Montant attendu',
              montant: chantier.montantTotalTTC - chantier.montantArchiInterneEncaisseTTC,
            },
          ]}
        />
      ) : (
        <div className="flex items-stretch flex-col sm-desktop:flex-row gap-md">
          <Compte
            label="Compte pro"
            progressAmount={chantier.montantVerseAuPro}
            totalAmount={chantier.montantTotalDuAuPro}
            variant="success"
            buttonGroupActions={[
              {
                label: 'Historique',
                value: 'historique',
                leftIcon: <History />,
                onClick: () => setIsLiberationHistoryModalOpen(true),
              },
              ...(chantier.proCompany.lemonwayAccountUrl
                ? [lemonwayAction(chantier.proCompany.lemonwayAccountUrl)]
                : []),
            ]}
            buttonActions={[
              {
                label: 'Suivi financier renforcé',
                leftIcon: <HandyManOutline />,
                variant: chantier.enableSuiviRenforce ? 'secondaryOutline' : 'secondary',
                disabled: !isAllowedToActivateSuiviRenforce,
                disabledMessageTooltip: 'Permission requise : Autorisé à activer le suivi renforcé',
                onClick: () => setIsSuiviFinancierRenforceModalOpen(true),
              },
            ]}
            amounts={[
              { label: 'Montant versé', montant: chantier.montantVerseAuPro },
              { label: 'Montant attendu', montant: chantier.montantTotalDuAuPro },
            ]}
          />
          <MontantGeleCard
            montantGele={chantier.montantGele}
            pv={chantier.pv}
            currentThreshold={chantier.currentThreshold}
            pourcentageAvancementGlobal={chantier.pourcentageAvancementGlobal}
            receptionChantierThreshold={chantier.receptionChantierThreshold}
            leveeReservesThreshold={chantier.leveeReservesThreshold}
            size="lg"
          />
        </div>
      )}
      {isLiberationHistoryModalOpen && (
        <LiberationDesFondsHistoryModal
          chantier={chantier}
          showAuthor
          onClose={() => setIsLiberationHistoryModalOpen(false)}
        />
      )}
      <SuiviFinancierRenforceModal
        isOpen={isSuiviFinancierRenforceModalOpen}
        chantier={chantier}
        handleClose={() => setIsSuiviFinancierRenforceModalOpen(false)}
      />
    </div>
  );
};
