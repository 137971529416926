import React from 'react';

export const TableHeader: React.FC = () => (
  <thead>
    <tr>
      <th>Nom</th>
      <th>Assurée pour</th>
      <th>N&apos;envoyer que sur</th>
      <th>Réalisés en interne</th>
      <th>Départements</th>
      <th>Evaluation / Commentaire</th>
      <th>Validité assurance</th>
      <th>Compte</th>
    </tr>
  </thead>
);
