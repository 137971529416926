import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';
import { getUrlParams } from '@travauxlib/shared/src/utils/urls';

import { useSignInAsClient } from 'api/useSignInAsClient';
import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { useClientAccounts } from './api/useClientAccounts';
import { CreateClientAccountModal } from './components/CreateClientAccountModal';

type Filters = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

type Props = { debounce?: number };

export const ClientAccounts: React.FC<Props> = ({ debounce = 800 }) => {
  useEffect(() => {
    NavigationTracking.onComptesClientsViewed();
  }, []);

  const urlFilters = getUrlParams(useLocation());
  const { isLoading, clientAccounts } = useClientAccounts(urlFilters);
  const navigate = useNavigate();
  const signInAsClient = useSignInAsClient();
  const [creationModalOpened, setCreationModalOpened] = useState(false);

  const onFilter = (values: Filters): void => {
    navigate(`?${new URLSearchParams(values as any).toString()}`);
  };

  return (
    <>
      <PageTitle title={PageTitleEnum.clientAccounts} />
      <CreateClientAccountModal
        isOpen={creationModalOpened}
        onClose={() => setCreationModalOpened(false)}
      />
      <Form<Filters> initialValues={urlFilters} onSubmit={onFilter}>
        {() => (
          <form>
            <Card className="mb-md overflow-visible">
              <AutoSubmitForm debounce={debounce} />
              <div className="flex flex-wrap -mx-md md-desktop:mb-md">
                <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                  <InputField label="Prénom" id="firstName" name="firstName" />
                </div>
                <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                  <InputField label="Nom" id="firstName" name="lastName" />
                </div>
                <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                  <InputField label="Email" id="email" name="email" />
                </div>
              </div>
            </Card>
          </form>
        )}
      </Form>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-wrap -mx-md !items-end !mb-sm">
            <div className="basis-0 flex-grow px-md relative">
              {clientAccounts.length} compte
              {clientAccounts.length > 1 && <>s</>}
            </div>
            <div className="basis-0 flex-grow px-md relative !text-right">
              <Button type="button" onClick={() => setCreationModalOpened(true)} size="sm">
                + Créer
              </Button>
            </div>
          </div>
          <Table
            columnConfigs={[
              {
                name: 'Création',
                columnKey: 'createdAt',
                renderValue: (createdAt: string) => <>{formatFrenchDate(createdAt)}</>,
              },
              {
                name: 'Prénom',
                columnKey: 'firstName',
                maxCharsForScreenSize: {
                  'sm-desktop': 10,
                  'md-desktop': 20,
                  'lg-desktop': 30,
                  tablet: 10,
                },
              },
              {
                name: 'Nom',
                columnKey: 'lastName',
                maxCharsForScreenSize: {
                  'sm-desktop': 10,
                  'md-desktop': 20,
                  'lg-desktop': 30,
                  tablet: 10,
                },
              },
              {
                name: 'Email',
                columnKey: 'email',
                maxCharsForScreenSize: {
                  'sm-desktop': 10,
                  'md-desktop': 20,
                  'lg-desktop': 30,
                  tablet: 10,
                },
              },
              {
                name: 'Chantiers',
                columnKey: 'email',
                renderValue: email => <Link to={`/chantiers?clientAccount=${email}`}>Voir</Link>,
              },
              {
                name: 'Leads',
                columnKey: 'email',
                renderValue: email => <Link to={`/leads?email=${email}`}>Voir</Link>,
              },
              {
                name: 'Action',
                columnKey: 'uuid',
                renderValue: (uuid: string) => (
                  <Button
                    type="button"
                    onClick={() => signInAsClient(uuid)}
                    className="!min-w-[13rem]"
                    size="sm"
                  >
                    Se connecter à son compte
                  </Button>
                ),
              },
            ]}
            items={clientAccounts}
            defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
          />
        </>
      )}
    </>
  );
};
