import { PersonRole } from '@travauxlib/shared/src/types';

import { Ability } from './AbilityBuilder';
import { abilityMap } from './abilityMap';
import { noRole } from './entities/NoRole';
import { Action, Subject, Flag } from './entities/types';

export function defineAbilityFor(role?: PersonRole): Ability<Action, Subject, Flag> {
  const ability = role ? abilityMap[role] : noRole;
  return ability;
}
