import React from 'react';

import { useParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ProDevisDisplay } from '@travauxlib/shared/src/features/ProDevisDisplay';
import { Devis } from '@travauxlib/shared/src/types';
import { getDevisFromProject } from '@travauxlib/shared/src/utils/getDevisFromProject';

import { useBaseDePrix } from 'api/useBaseDePrix';
import { useConfiguration } from 'features/Configurateur/api/useConfiguration';

export const ConfigurateurDevis: React.FC = () => {
  const projectUuid = useParams().uuid!;
  const { configuration } = useConfiguration(projectUuid!);

  const { baseDePrix } = useBaseDePrix();

  if (!configuration || !baseDePrix) {
    return <Loader />;
  }

  const partialDevis = getDevisFromProject({
    typologieProject: configuration,
    configurationData: { baseDePrix, configuration },
  });

  const devis = {
    ...partialDevis,
    showFournitures: true,
  } as Devis;

  return <ProDevisDisplay devis={devis} />;
};
