import React from 'react';

import { useParams } from 'react-router-dom';

import { Header } from './Header';
import { MembersList } from './MembersList';

type Props = {
  teamUuid?: string;
};

export const Team: React.FC<Props> = () => {
  const { teamUuid } = useParams();
  return (
    <>
      <Header />
      <MembersList teamUuid={teamUuid!} />
    </>
  );
};
